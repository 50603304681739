
.footer {
		padding: 8rem 0;
}


@media only screen and (min-width:992px) {
	.footer {
		padding: 5vw 0;
	}
}