/* General Styling */

.container {
    max-width: 140rem;
    @media only screen and (min-width: 100rem) {
        padding: 0 5rem;
    }
}

:root{
    --bg: #f4f4f4;
    --black-text: #212838;
    --grey-text: #212838;
    --light-text: #646974;
    //--accent: #e3efe3;
    //--accent: #4F543D;
    --accent: #D5D6D1;
    --white-text: #fff;
    --white: #fff; 
}
body{
    background: var(--bg) !important;
    color: var(--black-text);
    font-family: "Suisse Int'l", "Inter", system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    letter-spacing: -0.02em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.container-fluid{
    padding: 0px 4vw;
}
.container-fluid-small{
    padding: 0px 15vw;
    width: 100%;
}
.page-trans {
    position: fixed;
    top:0;
    left:0;
    background: #000;
    width:100vw;
    height:100vh;
    z-index: 9999;
}
.img-anim{
    position: relative;
    overflow: hidden;
    img{
        max-width: 100%;
        width: 100%;
        transform: scale(1.1);
    }
    .img-anim-bg{
        position: absolute;
        width: 100vw;
        height: 100%;
        background: var(--bg);
        z-index: 999;
        top: 0;
        right: 0;
    }
}
h1{
    line-height: 1.25;
    font-weight: 500;
    letter-spacing: -0.25px;
    color: var(--black-text);
    &.section-big-title{
        font-size: 2.5rem;
    }
}
p{
    font-size: 1.25rem;
    line-height: 1.65;
    color: var(--grey-text);
}
a:hover, a:focus{
    text-decoration: none;
}
.header-anim{
    will-change: transform;
    transform: translateY(15px);
    opacity: 0;
    transition: all .3s ease-in-out;
}
.header-anim-on{
    will-change: transform;
    transform: translateY(0px);
    opacity: 1;
    transition: all .3s ease-in-out;
}
.hero-title{
    will-change: transform;
    transform: translateY(25px);
    opacity: 0;
    transition: all .3s ease-in-out;
}
.hero-title-on{
    will-change: transform;
    transform: translateY(0px);
    opacity: 1;
    transition: all .3s ease-in-out;
}
.hero-cta{
    will-change: transform;
    transform: translateY(25px);
    opacity: 0;
    transition: all .3s ease-in-out;
}
.hero-cta-on{
    will-change: transform;
    transform: translateY(0px);
    opacity: 1;
    transition: all .3s ease-in-out;
}
.img-anim-bg-on{
    width: 0vw !important;
    transition: all .3s ease-in-out;
}
.img-anim-on img{
    transform: scale(1) !important;
    transition: all .5s ease-in-out;
}
.anim-bot{
    will-change: transform;
    transform: translateY(50px);
    opacity: 0;
    transition: all .5s ease-in-out;
}
.item-anim-bot{
    transform: translateY(0) !important;
    opacity: 1 !important;
    transition: all .5s ease-in-out;
}
.anim-bot-single-img{
    will-change: transform;
    transition: all .5s ease-in-out;
}
.item-anim-bot-single-img{
    transform: translateY(0) !important;
    opacity: 1!important;
    transition: all .5s ease-in-out;
}
.green-bg{
    background: var(--black-text);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
.green-anim{
    width: 0;
    transition: all .5s ease-in-out;
}
.item-anim-green{
    width: 100%;
    transition: all .5s ease-in-out;
}

// Helpers

.hidden-xs {
    display: none;
    @media only screen and (min-width: 768px) {
        display: block;
    }
}
.xs-only {
    display: block;
    @media only screen and (min-width: 768px) {
        display: none;
    }
}

.lead-dark {
    background: #212838;
}

.bg-impact {
    background: #E9ECF0;
}

// CSS Updates

.section-lead {
    padding: 5rem 0;
    @media only screen and (min-width: 992px) {
        padding: 15vh 0;
    }
}

h3.lead-title, .lead-title {
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 1.5;
    .text-muted {
        font-feature-settings: 'fake' 1, 'zero' 1;
    }
}

h4.lead-subtitle {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.3333;
    margin-bottom: 0.75em;
}

.lead-content, .lead-text, p.lead-text {
    font-size: 1rem;
    line-height: 1.5;
}

.lead-small, p.lead-small {
    font-size: 1rem;
    line-height: 1.5;
}

.tech-item {
    display: flex;
    align-items: center;
    margin: 1em 0;
    img {
        margin-right: 1em;
        height: 12vw;
        width: auto;
    }
}

@media only screen and (min-width:768px) {
    .tech-item {
        img {
            height: 3vw;
        }
    }
}


.highlighted-link {
    color: var(--black-text);
    position: relative;
    &::after {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        right: 0;
        height: 10%;
        width: 100%;
        background: rgba(0,0,0,0.1);
    }
    &:hover {
        color: var(--light-text);
        &::after {
            background: rgba(0,0,0,0.2)
        }
    }
}

#heroSection{
    position: relative;
    padding: 10rem 0;
    background: #E9ECF0;
    display: flex;
    align-items: center;
    h1{
        line-height: 1.25;
        font-weight: 400;
        font-size: 3rem; 
    }
    p{
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.5;
    }
    
    .hero-button{
      margin-top: 3rem;
      a{
        color: var(--black-text);
        font-size: 1.25vw;
        font-weight: 400;
        padding: 17px 17px 17px 25px;
        border: 1px solid #dbdbdb;
        transition: all .3s ease-in-out;
      }
      a:hover{
        background: var(--accent);
        border: 1px solid var(--accent);
        transition: all .3s ease-in-out;
      }
    }
    .about-hero{
      h1{
        margin-bottom: 1.5rem;
      }
    }
}

.lead-btn {
    font-size: 1.25rem;
    padding: 1em 2.5em;
    display: inline-block;
    transition: all .3s ease-in-out;
    background: rgba(0,0,0,0.1);
    color: #fff;
    &:hover {
        background: #fff;
        color: var(--black-text);
    }
}

.lead-work-item {
    margin-bottom: 3rem;
}

.vw-pt-3 {
    padding-top: 3vw;
}

.vw-pb-3 {
    padding-bottom: 3vw;
}

.vw-py-3 {
    padding-top: 3vw;
    padding-bottom: 3vw;
}

.team-member {
    text-align: center;
    img {
        width: 100%;
        border-radius: 100rem;
    }
    .lead-subtitle {
        margin-top: 0.75em;
        margin-bottom: 0.25em;
    }
}

@media only screen and (min-width: 992px) {
    .lead-btn {
        font-size: 1.25vw;
    }
    h4.lead-subtitle {
        font-size: 1.75vw;
    }
    h3.lead-title, .lead-title {
        font-size: 2vw;
    }
    .lead-content, .lead-text, p.lead-text {
        font-size: 1.75vw;
    }
    .lead-small, p.lead-small {
        font-size: 1.25vw;
    }
    
    #heroSection {
        height: 70vh;
        h1 {
            font-size: 5vw;
        }
        p {
            font-size: 2vw;
        }
    }
    .lead-work-item {
        margin-bottom: 3vw;
    }
}
