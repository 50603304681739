/* Contact page styling */
.contact-section{
    .contact-right{
      input{
          width: 100%;
          background: transparent;
          border: 0;
          border: 1px solid #dbdbdb;
          padding: 10px;
          margin: 10px 0px 10px 0px;
          font-weight: 400;
          font-size: 1em;
          color: var(--grey-text);
          transition: .25s cubic-bezier(.75,.25,.25,.75);
          &::placeholder{
            color: var(--light-text);
              font-weight: 400;
          }
          &:focus{
              outline: 0;
              background: #fff;
              border-color: var(--dark-text);
              transition: .25s cubic-bezier(.75,.25,.25,.75);
          }
      }
      textarea{
          width: 100%;
          background: transparent;
          border: 0;
          border: 1px solid #dbdbdb;
          padding: 10px;
          margin: 10px 0px 10px 0px;
          font-weight: 400;
          min-height: 145px;
          font-size: 1em;
          color: var(--grey-text);
          transition: .25s cubic-bezier(.75,.25,.25,.75);
          &::placeholder{
              color: var(--light-text);
              font-weight: 400;
          }
          &:focus{
              outline: 0;
              transition: .25s cubic-bezier(.75,.25,.25,.75);
              background: #fff;
              border-color: var(--dark-text);
          }
      }
      button{
          border: 1px solid var(--accent);
          background: var(--accent);
          color: var(--text);
          font-size: 1rem;
          font-weight: 400;
          text-transform: capitalize;
          padding: 13px 18px;
          margin-top: 1rem;
          transition: all .3s ease-in-out;
          cursor: pointer;
          &:focus{
              outline: 0;
              text-decoration: none;
          }
      }
    }
    .success-message{
      padding-top: 10px;
      text-align: center;
      i{
        font-size: 24px;
        color: var(--light-text);
      }
      p{
        color: var(--light-text) !important;
        text-align: center;
      }
    }
}