/* Header styling */
header{
    padding: 1.5rem 0 1.5rem 0;
    position: absolute;
    position: sticky;
    background: #000;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    .site-logo{
        display: flex;
        align-items: center;
        a{
            color: var(--text);
            font-size: 19px;
            font-weight: 500;
            letter-spacing: 0px;
        }
    }
    .bayLogo {
        height: 2rem;
    }
    .main-menu{
        display: flex;
        justify-content: flex-end;
        ul{
            display: flex;
            align-items: center;
            list-style: none;
            padding-left: 0px;
            margin-bottom: 0;
        li{
            display: inline-block;
            a{
                color: var(--light-text);
                font-size: 1rem;
                margin: 0 0 0 50px;
                font-weight: 400;
                border-bottom: 1px solid transparent;
                transition: all .3s ease-in-out;
                &.active{
                    color: var(--white-text);
                }
                &:hover{
                    color: var(--white-text);
                    border-bottom: 1px solid var(--white);
                    transition: all .3s ease-in-out;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1499px) {
    header {
        .bayLogo {
            height: 2.5vw;
        }
    }
}

@media only screen and (min-width: 1500px) {
    header {
        padding: 1.5vw 0 1.5vw 0;
        .bayLogo {
            height: 3vw;
        }
        .main-menu{
            display: flex;
            justify-content: flex-end;
            ul{
                
                li{
                    display: inline-block;
                    a{
                        font-size: 1.25vw;
                        margin: 0 0 0 3vw;
                    }
                    }
                    
                }
        }
    }
}