/* Responsive Design */
@media (max-width: 1300px){
    .container-fluid{
      padding: 0 2rem;
    }
    header{
      padding-top: 1.5rem;
    }
  }
  @media (max-width: 1200px){
    .container-fluid{
      padding: 0 25px;
    }
    header{
      padding-top: 2.5rem;
    }
    h1.section-big-title {
      font-size: 1.75rem;
    }
    #singleNews{
      .single-news-right{
        margin-top: 30px;
      }
    }
  }
  @media (max-width: 992px){
    #sectionFooter{
      .social-footer-menu{
        ul{
          text-align: right;
          margin-bottom: 5px;
          li{
            margin-left: 20px;
            margin-right: 0px;
            a{
              font-size: .9rem;
            }
          }
        }
        p{
          text-align: right;
          font-size: .8rem;
        }
      }
    }
  }
  @media (max-width: 768px){
    .container-fluid{
      padding: 0 1.5rem;
    }
    .container{
      padding: 0 1.5rem;
    }
    h1.section-big-title{
      font-size: 1.25rem;
      letter-spacing: -.5px;
    }
    header{
      padding-top: 1.5rem;
    }
    #homeCtaSection{
      padding-bottom: 50px;
      .row{
        margin-top: 50px;
        h1{
          margin-bottom: 15px;
        }
        ul{
          margin-top: 15px;
          margin-bottom: 20px;
          li{
            font-size: .9rem;
          }
        }
        p{
          font-size: .9rem;
        }
      }
    }
    #sectionContact{
      padding-bottom: 75px;
      .contact-left{
        margin-bottom: 30px;
        ul{
          margin-top: 20px;
        }
        h2{
          font-size: 1.5rem;
          font-weight: 400;
          margin-bottom: 24px;
        }
        h6{
          margin-bottom: 0px;
          &.phone{
            margin-top: 8px;
          }
        }
        h5{
          margin-bottom: 15px;
        }
        ul{
          text-align: left;
          margin-bottom: 10px;
          li{
            margin-left: 0px;
            margin-right: 20px;
          }
        }
        p{
          text-align: left;
        }
      }
    }
    #sectionFooter{
      .footer-contact-box{
        h6{
          margin-bottom: 0px;
          font-size: .9rem;
          &.phone{
            margin-top: 8px;
          }
        }
        h5{
          margin-bottom: 15px;
          font-size: .95rem;
          margin-top: 5px;
        }
      }
      .footer-address-box{
        margin-top: 0px;
      }
      .footer-logo{
        h3{
          margin-bottom: 20px;
          font-size: 18px;
        }
      }
    }
  }
  
  @media (max-width: 767px){
    header{
      padding-top: 25px;
      .main-menu{
        justify-content: flex-end;
        ul{
          li{
            text-align: right;
            a{
              margin: 0 0 0 1rem;
            }
          }
        }
      }
    }
    #sectionFooter{
      .footer-address-box{
        margin-top: 10px;
      }
      .social-footer-menu{
        margin-top: 20px;
        ul{
          text-align: left;
          margin-bottom: 5px;
          li{
            margin-left: 0px;
            margin-right: 20px;
            a{
              font-size: .9rem;
            }
          }
        }
        p{
          text-align: left;
          font-size: .8rem;
        }
      }
    }
  }

  @media (max-width: 500px){
    #heroSection{
      padding-right: 0px;
      h1{
        padding-right: 50px;
        br{
          display: none;
        }
      }
      &.short{
        height: 65vh !important;
        padding-top: 75px;
      }
    }
  }
  
  @media (max-width: 360px){
    .container-fluid{
      padding: 0 15px;
    }
    .container{
      padding: 0 15px;
    }
    #heroSection{
      height: 95vh !important;
    }
    .short{
      height: 90vh !important;
    }
    #portfolioSection{
      .portfolio-filters{
        .portfolio-nav{
          a{
            font-size: .85rem;
            padding: 8px 12px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  